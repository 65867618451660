import { Entity } from '@sqior/js/entity';
import { ErrorReportingMode, Logger } from '@sqior/js/log';
import { MappingInterface } from './mapping-interface';

/** Anonymization level for event history */

export enum EventHistoryLevel {
  Retain,
  Pseudonymize,
  Expurgate,
}

/** Model interface */

export interface ModelInterface {
  /** Type of model */
  type: string;
  /** Base type (if applicable) */
  base: string | undefined;
  /** Flag if type is keyable */
  keyable: boolean;
  /** Anonymization mode for event history */
  eventHistoryLevel: EventHistoryLevel;

  /** Calculates a "unique" key for an entity of this type */
  key(models: Models, entity: Entity): string;
  /** Properties to be found in an entity of this type */
  properties(models: Models): Record<string, boolean>;

  /** Validates an entity to be conformant as a mapping result of this type */
  validateResult(
    entity: Entity,
    models: Models,
    mapper: MappingInterface,
    mode?: ErrorReportingMode
  ): boolean;

  /** Validates the model */
  validateModel(models: Models): void;
  /** Checks if the provided model is equal to this */
  isEqual(that: ModelInterface): boolean;
}

/** Registry of models */

export class Models {
  /** Validates the models */
  validateModels() {
    for (const model of this.models.values()) model.validateModel(this);
  }

  /** Registers a new model */
  add(model: ModelInterface) {
    /* Check if the model is not already registered with incompatible information */
    const exModel = this.models.get(model.type);
    if (exModel) {
      if (!exModel.isEqual(model))
        throw new Error('Model is already registered - type provided: ' + model.type);
    } else this.models.set(model.type, model);
  }

  /** Checks if a certain type is registered */
  has(type: string) {
    return this.models.has(type);
  }

  /** Returns a model for a specified type */
  get(type: string) {
    const model = this.models.get(type);
    if (model) return model;
    throw new Error('Model of specified type not found - type provided: ' + type);
  }

  /** Integrates all models */
  integrate(that: Models) {
    for (const model of that.models.values()) this.add(model);
  }

  /* Creates a key for an entity */
  key(entity: Entity): string {
    return this.get(entity.entityType).key(this, entity);
  }

  /* Checks if a type extends another type */
  extends(derived: string, base: string) {
    while (derived !== base) {
      const parent = this.get(derived).base;
      if (!parent) return false;
      derived = parent;
    }
    return true;
  }

  /** Returns the properties to be found in an object of this type */
  properties(type: string) {
    return this.get(type).properties(this);
  }
  /** Returns a list of properties to be found in an object of this type */
  propertyList(type: string) {
    const props: string[] = [];
    for (const key in this.get(type).properties(this)) props.push(key);
    return props;
  }

  /** Validates whether an entity conforms to its model */
  validate(entity: Entity, mode?: ErrorReportingMode): boolean {
    /* Check if the entity type is known */
    const model = this.models.get(entity.entityType);
    if (!model) {
      Logger.reportError(['Entity with unknown entity type:', entity.entityType], mode);
      return false;
    }
    /* Check if the entity only contains registered properties */
    let ret = true;
    const props = model.properties(this);
    for (const key in entity)
      if (key !== 'entityType' && props[key] === undefined) {
        Logger.reportError(
          ['Entity type:', entity.entityType, 'with unknown property:', key],
          mode
        );
        ret = false;
      }
    return ret;
  }

  /** Validates a mapping result */
  validateResult(
    entity: Entity,
    type: string,
    mapper: MappingInterface,
    mode?: ErrorReportingMode
  ): boolean {
    /* Check if the expected type is an interface */
    const model = this.models.get(type);
    if (!model) {
      Logger.reportError(['Unknown type for validation:', type], mode);
      return false;
    }
    return model.validateResult(entity, this, mapper, mode);
  }

  readonly models = new Map<string, ModelInterface>();
}
