import { PluginContext } from '@sqior/js/plugin';
import { StateOverlayContext } from '@sqior/js/state-operation';
import { FactoryContext } from '@sqior/react/factory';
import { UIVisualPlugin } from '@sqior/react/uivisual';
import {
  GraphEdgeDataTypeStatistic,
  OccupancyAnalyticsDashboard,
  ORWorkflowAnalyticsDashboard,
} from '@sqior/viewmodels/analytics-dashboard';
import { lazy } from 'react';

const BmAnalyticsFC = lazy(() => import('./bm-analytics/bm-analytics'));
const OpAnalyticsFC = lazy(() => import('./op-analytics/op-analytics'));
const FlowChartLabel = lazy(() => import('./flow-chart-label/flow-chart-label'));

/** Plugin for react components related to treatment path handling */

export function UIAnalyticsPlugin(context: PluginContext & FactoryContext & StateOverlayContext) {
  context.plugins.require(UIVisualPlugin, context);
  /* Factory components */
  context.factory.add(OccupancyAnalyticsDashboard, BmAnalyticsFC);
  context.factory.add(ORWorkflowAnalyticsDashboard, OpAnalyticsFC);
  context.factory.add(GraphEdgeDataTypeStatistic, FlowChartLabel);
}
