import { EntityHeader } from '@sqior/js/entity';
import { MetricCardStats, TimeRange } from './types';
import { ListData, ListSelectionData } from '@sqior/viewmodels/input';

export const OccupancyAnalyticsDashboard = 'OccupancyAnalyticsDashboard';

export type OccupancyAnalyticsDashboardVM = EntityHeader & OccupancyAnalyticsData;

export type OccupancyAnalyticsData = {
  statePath: string;
  wards: ListSelectionData<ListData[]>;
  timeRanges: ListSelectionData<TimeRange[]>;
  stats: WardStatDataBase;
};

export type WardStatDataBase = Record<OccupancyMetricCardTypes, MetricCardStats>;

export enum OccupancyMetricCardTypes {
  Spi = 'spi',
  OccupancyRate = 'occupancyRate',
  StayDuration = 'stayDuration',
}
