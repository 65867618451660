import { EntityHeader } from '@sqior/js/entity';
import { OperationSpec, OperationType } from '@sqior/js/operation';
import { joinPath } from '@sqior/js/url';
import { AnalyticsDashboardStatePath } from './paths';

export const ORWorkflowAnalyticsDashboard = 'ORWorkflowAnalyticsDashboard';
export const ORWorkflowAnalyticsDashboardOpPath = 'ORWorkflowAnalyticsDashboard';

export enum ORWorkflowAnalyticsDashboardOpSubPaths {
  SelectEdge = 'SelectEdge',
  SelectNode = 'SelectNode',
  SelectTimeRange = 'SelectTimeRange',
  SelectSpecialty = 'SelectSpecialty',
  SelectFilter = 'SelectFilter',
}

export type ORWorkflowAnalyticsDashboardVM = EntityHeader & {
  // new stuff to add
};

export type ORWorkflowAnalyticsDashboardStateOperationData = {
  addUnknownNodes?: boolean;
  filterEdgesBelowCount?: number;
};

export function ORWorkflowAnalyticsDashboardOperation(
  data: ORWorkflowAnalyticsDashboardStateOperationData
): OperationSpec<ORWorkflowAnalyticsDashboardStateOperationData> {
  return { type: OperationType.Add, path: AnalyticsDashboardStatePath, data };
}

export function ORWorkflowAnalyticsDashboardSelectEdge(id: string[]): OperationSpec<string[]> {
  return {
    type: OperationType.Add,
    path: joinPath(AnalyticsDashboardStatePath, ORWorkflowAnalyticsDashboardOpSubPaths.SelectEdge),
    data: id,
  };
}

export function ORWorkflowAnalyticsDashboardSelectNode(id: string[]): OperationSpec<string[]> {
  return {
    type: OperationType.Add,
    path: joinPath(AnalyticsDashboardStatePath, ORWorkflowAnalyticsDashboardOpSubPaths.SelectNode),
    data: id,
  };
}

export function ORWorkflowAnalyticsDashboardTimeRange(timeRangeId: string): OperationSpec<string> {
  return {
    type: OperationType.Add,
    path: joinPath(
      AnalyticsDashboardStatePath,
      ORWorkflowAnalyticsDashboardOpSubPaths.SelectTimeRange
    ),
    data: timeRangeId,
  };
}

export function ORWorkflowAnalyticsDashboardSelectSpecialty(id: string): OperationSpec<string> {
  return {
    type: OperationType.Add,
    path: joinPath(
      AnalyticsDashboardStatePath,
      ORWorkflowAnalyticsDashboardOpSubPaths.SelectSpecialty
    ),
    data: id,
  };
}

export function ORWorkflowAnalyticsDashboardSelectFilter(id: string): OperationSpec<string> {
  return {
    type: OperationType.Add,
    path: joinPath(
      AnalyticsDashboardStatePath,
      ORWorkflowAnalyticsDashboardOpSubPaths.SelectFilter
    ),
    data: id,
  };
}
