import styles from './orworkflow-list-item.module.css';
import { FactoryProps } from '@sqior/react/factory';
import { OperationContext } from '@sqior/react/operation';
import { InterweaveExt } from '@sqior/react/uibase';
import { useContext } from 'react';
import { ORWorkflowListItemBase } from '@sqior/viewmodels/orworkflow';
import { ItemState } from '@sqior/viewmodels/visual';
import ORWorkflowIndicatorControl from '../orworkflow-indicator-control/orworkflow-indicator-control';

export type ORWorkflowListItemProps = FactoryProps<ORWorkflowListItemBase>;

export function ORWorkflowListItem(props: ORWorkflowListItemProps) {
  const dispatcher = useContext(OperationContext);
  const emergency = props.data.emergencyClassification?.category;

  return (
    <div className={styles['main-container']}>
      <div
        className={styles['container']}
        onClick={() => {
          if (props.data.select) dispatcher.start(props.data.select);
        }}
      >
        <div className={styles['patient-container']}>
          {emergency && (
            <span className={styles['emergency']}>
              <b>{emergency.toUpperCase()}</b>
            </span>
          )}
          {props.data.prioGroup && (
            <span
              className={
                styles[props.data.prioGroup.state === ItemState.Challenged ? 'challenge' : 'normal']
              }
            >
              {props.data.prioGroup.label}
            </span>
          )}
          <InterweaveExt
            className={
              styles[
                props.data.patientState === ItemState.Challenged
                  ? 'challenge'
                  : props.data.patientState === ItemState.Highlighted
                  ? 'highlight'
                  : props.data.patientState === ItemState.Greyed
                  ? 'greyed'
                  : props.data.patientState === ItemState.Hot
                  ? 'hot'
                  : 'normal'
              ]
            }
            content={props.data.patient}
          />
        </div>
        {props.data.step && <InterweaveExt content={props.data.step} />}
        {props.data.diagnosis && <div>{props.data.diagnosis}</div>}
        {props.data.description && <div>{props.data.description}</div>}
        {props.data.location && <InterweaveExt content={props.data.location} />}
      </div>
      <div className={styles['icon-state']}>
        {props.data.indicator && <ORWorkflowIndicatorControl indicator={props.data.indicator} />}
      </div>
    </div>
  );
}

export default ORWorkflowListItem;
