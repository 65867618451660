export enum Icons {
  Bed = 'bed',
  Calender = 'calender',
  Cancel = 'cancel',
  ChangeDescription = 'changeDescription',
  ChangeLocation = 'changeLocation',
  Chat = 'chat',
  Check = 'check',
  Clock = 'clock',
  Escalation = 'escalation',
  Inserted = 'inserted',
  Oneway = 'oneway',
  Reordered = 'reordered',
  Transport = 'transport',
  EventBusy = 'eventBusy',
  Lock = 'lock',
  Report = 'report',
  EventAvailable = 'eventAvailable',
  EventUpcoming = 'eventUpcoming',
  PatientPositioning = 'patientPositioning',
}
