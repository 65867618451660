import { AuthConfig, UserInfoType } from '@sqior/js/authbase';
import Keycloak from 'keycloak-js';
import { OAuthProvider } from './oauth-provider';

export class KeycloakProvider extends OAuthProvider<Keycloak> {
  override async initializeClient(
    token?: string | undefined,
    refreshToken?: string | undefined,
    offlineAccess?: boolean,
    additionalScopes?: string[]
  ): Promise<boolean> {
    if (additionalScopes) this.additionalScopes = additionalScopes;
    return (
      this.client?.init({
        checkLoginIframe: false,
        token: token,
        refreshToken: refreshToken,
        onLoad: 'check-sso', // Enables to continue session for new tabs or browser windows
        redirectUri: this.redirectUri,
        enableLogging: true,
        scope: this.getScopesAsString(),
        messageReceiveTimeout: 30000,
      }) ?? false
    );
  }
  override createClient(config: AuthConfig, appUrl: string): Keycloak {
    return new Keycloak({
      url: config.sqiorAuthBaseUrl,
      realm: config.sqiorAuthRealm,
      clientId: config.sqiorAuthClientId,
    });
  }
  override isLoggedIn(user: string): boolean {
    return (
      user === (this.client?.idTokenParsed ? this.client.idTokenParsed['preferred_username'] : '')
    );
  }
  override get user(): string | undefined {
    if (!this.client?.idTokenParsed) return 'dummyUserName';
    return this.client.idTokenParsed['preferred_username'] ?? this.client.idTokenParsed['name'];
  }
  override get userInfo(): UserInfoType {
    return this.client?.idTokenParsed
      ? { name: this.client.idTokenParsed['name'], userId: this.client.idTokenParsed['sub'] }
      : {};
  }
  override get typeName() {
    return 'Keycloak';
  }
}
