import { Emitter } from '@sqior/js/event';
import { Logger } from '@sqior/js/log';
import { OperationState } from '@sqior/js/operation';
import { BrowserMultiFormatReader } from '@zxing/library';
import { useEffect, useRef, useState } from 'react';
import AudioSRC from './detect.mp3';
import styles from './qrscanner.module.css';
import { Value, ValueOrNothing } from '@sqior/js/data';

export enum FacingMode {
  Environment,
  User,
}

export interface QRScannerProps {
  takePhoto?: Emitter;
  onPhoto?: (canv: HTMLCanvasElement) => void;
  facing?: FacingMode;
  onFacing?: (facing: FacingMode) => void;
  onDetected?: (code: string) => Promise<ValueOrNothing>;
  onClose?: (data: Value) => void;
}

interface QRPoint {
  x: number;
  y: number;
  estimatedModuleSize: number;
  count?: number; // The count property is optional since it's not present in all objects
}

const detectionAudio = new Audio(AudioSRC);

const drawCorners = (ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement, active: boolean) => {
  // Center point
  const centerX = canvas.width / 2;
  const centerY = canvas.height / 2;

  // Rectangle dimensions
  const rectWidth = 200;
  const rectHeight = 200;

  // Corner length and width
  const cornerLength = 20;
  const cornerWidth = 4;

  // Set the composite operation to "destination-out" to erase
  ctx.globalCompositeOperation = 'destination-out';

  // Clear rectangle area
  ctx.clearRect(centerX - rectWidth / 2, centerY - rectHeight / 2, rectWidth, rectHeight);

  // Set the composite operation back to "source-over" to draw
  ctx.globalCompositeOperation = 'source-over';
  ctx.strokeStyle = 'black'; // or any other color that matches your design

  const drawSingleCorner = (x: number, y: number, isTop: boolean, isLeft: boolean) => {
    ctx.beginPath();

    if (isTop && isLeft) {
      ctx.moveTo(x, y + cornerLength);
      ctx.lineTo(x, y);
      ctx.lineTo(x + cornerLength, y);
    } else if (isTop && !isLeft) {
      ctx.moveTo(x, y + cornerLength);
      ctx.lineTo(x, y);
      ctx.lineTo(x - cornerLength, y);
    } else if (!isTop && isLeft) {
      ctx.moveTo(x, y - cornerLength);
      ctx.lineTo(x, y);
      ctx.lineTo(x + cornerLength, y);
    } else {
      ctx.moveTo(x, y - cornerLength);
      ctx.lineTo(x, y);
      ctx.lineTo(x - cornerLength, y);
    }

    ctx.lineWidth = cornerWidth;
    ctx.strokeStyle = active ? '#1cade4' : 'white';
    ctx.stroke();
  };

  // Draw corners
  drawSingleCorner(centerX - rectWidth / 2, centerY - rectHeight / 2, true, true);
  drawSingleCorner(centerX + rectWidth / 2, centerY - rectHeight / 2, true, false);
  drawSingleCorner(centerX + rectWidth / 2, centerY + rectHeight / 2, false, false);
  drawSingleCorner(centerX - rectWidth / 2, centerY + rectHeight / 2, false, true);
};

export function QRScannerControl(props: QRScannerProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const [QRState, setQRState] = useState(OperationState.NotStarted);
  const opStarted = useRef(false);
  const [facing, setFacing] = useState(props.facing === FacingMode.User ? 'user' : 'environment');

  const handleOnSuccess = (data: Value) => {
    detectionAudio.play();

    setTimeout(() => {
      props.onClose?.(data);
    }, 400);
  };

  const handleOnFailure = () => {
    console.log('QR code failed');
  };

  const onStateChange = (data: ValueOrNothing) => {
    setTimeout(() => {
      setQRState(data !== undefined ? OperationState.Completed : OperationState.Failed);
      if (data !== undefined) handleOnSuccess(data);
      else handleOnFailure();
    }, 300);
  };

  const onDetection = (code: string) => {
    if (opStarted.current) return;
    opStarted.current = true;
    setQRState(OperationState.Running);
    const res = props.onDetected?.(code);
    if (res)
      res
        .then((data) => {
          onStateChange(data);
        })
        .catch((e) => {
          onStateChange(undefined);
        });
  };

  const resizeCanvasToMatchVideo = () => {
    if (!canvasRef.current || !videoRef.current || !containerRef.current) return;
    canvasRef.current.width = containerRef.current.offsetWidth;
    canvasRef.current.height = containerRef.current.offsetHeight;
  };

  const drawRectangle = (points: QRPoint[] | undefined, QRState: OperationState | undefined) => {
    if (!canvasRef.current || !videoRef.current) return;

    const ctx = canvasRef.current.getContext('2d');
    if (!ctx) return;
    const canvas = canvasRef.current;

    if (QRState === OperationState.Failed) {
      // Fill entire canvas with red
      ctx.fillStyle = 'rgba(255,0,0,0.3)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      setTimeout(() => {
        opStarted.current = false;
        setQRState(OperationState.NotStarted);
      }, 300);
    } else {
      // Clear canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
    drawCorners(
      ctx,
      canvas,
      QRState === OperationState.Running || QRState === OperationState.Completed
    );
  };

  /* Connect the photo emitter if applicable */
  useEffect(() => {
    const onPhoto = props.onPhoto;
    if (props.takePhoto && onPhoto)
      return props.takePhoto.on(() => {
        if (!videoRef.current || !videoRef.current.videoWidth || !videoRef.current.videoHeight)
          return;
        /* Draw video element into canvas with original video size */
        const canv = document.createElement('canvas');
        canv.width = videoRef.current.videoWidth;
        canv.height = videoRef.current.videoHeight;
        canv.getContext('2d')?.drawImage(videoRef.current, 0, 0);
        /* Inform users */
        onPhoto(canv);
      });
    else return;
  }, [props]);

  useEffect(() => {
    const codeReader = new BrowserMultiFormatReader();
    let videoStream: MediaStream | undefined;
    let stopped = false;
    let retryCount = 0;
    let startTimer: ReturnType<typeof setTimeout> | undefined = undefined;

    Logger.debug('QRScannerControl - useEffect');

    function getUserMedia() {
      Logger.debug('QRScannerControl - try to get user media');
      navigator.mediaDevices
        .getUserMedia({
          video: {
            width: { ideal: props.takePhoto ? 3840 : 1280 },
            height: { ideal: props.takePhoto ? 2160 : 720 },
            facingMode: facing,
          },
        })
        .then((stream) => {
          Logger.debug(
            `QRScannerControl - getUserMedia - returned stream stopped=${stopped}, videoControl.current=${videoRef.current}`
          );
          /* Check if this is already stopped, in this case the code reader should not be started at all */
          if (stopped) return;
          /* Start decoding */
          videoStream = stream;
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            Logger.debug(`QRScannerControl - call codeReader.decodeFromVideoElementContinuously`);
            codeReader.decodeFromVideoElementContinuously(videoRef.current, (result, err) => {
              //Logger.debug(`QRScannerControl - codeReader.decodeFromVideoElementContinuously - callback, result=${result?.getText()}`);
              if (result) {
                if (result.getText() && !stopped) {
                  onDetection(result.getText());
                }
              }
            });
          }
        })
        .catch((r) => {
          Logger.debug(
            `QRScannerControl - getUserMedia - error: ${JSON.stringify(
              r
            )}, retryCount=${retryCount}, stopped=${stopped}`
          );
          // Retry once, might happen if video has been consumed recently
          if (retryCount < 1 && !stopped) {
            retryCount++;
            triggerGetUserMedia();
          }
        });
    }

    function triggerGetUserMedia() {
      Logger.debug(
        `QRScannerControl - triggerGetUserMedia(), retryCount=${retryCount}, stopped=${stopped}, startTimer=${startTimer}`
      );
      startTimer = setTimeout(() => {
        Logger.debug(
          `QRScannerControl - triggerGetUserMedia() - timout, retryCount=${retryCount}, stopped=${stopped}`
        );
        if (!stopped) {
          getUserMedia();
        }
      }, 200);
    }

    triggerGetUserMedia();

    return () => {
      Logger.debug(
        `QRScannerControl - useEffect - destructor, retryCount=${retryCount}, stopped=${stopped}, videoStream=${videoStream}, startTimer=${startTimer}`
      );
      /* Indicate that this is stopped in case that the getUserMedia function or other asynchronous callbacks are still running */
      stopped = true;

      if (startTimer) clearTimeout(startTimer);
      /* Check if this is already started */
      if (videoStream) {
        codeReader.reset();
        Logger.debug(`QRScannerControl - useEffect - destructor, reset()`);
        for (const track of videoStream.getTracks()) {
          track.stop();
          Logger.debug(`QRScannerControl - useEffect - destructor, track.stop() track=${track}`);
        }
      }
    };
  }, [props, facing]);

  useEffect(() => {
    resizeCanvasToMatchVideo();
    drawRectangle([], QRState);
  }, [QRState]);

  return (
    <div
      ref={containerRef}
      style={{ position: 'relative', overflow: 'hidden', height: '100%', width: '100%' }}
    >
      <video
        ref={videoRef}
        style={{
          height: '100%',
          width: '100%',
          objectFit: 'cover',
        }}
        onClick={() => {
          /* Check if the device has multiple video sources, if yes try to change the facing mode */
          navigator.mediaDevices.enumerateDevices().then((devs) => {
            let videoDevices = 0;
            for (const dev of devs) if (dev.kind === 'videoinput') videoDevices++;
            if (videoDevices > 1) {
              const newFacing = facing === 'user' ? 'environment' : 'user';
              setFacing(newFacing);
              if (props.onFacing)
                props.onFacing(newFacing === 'user' ? FacingMode.User : FacingMode.Environment);
            }
          });
        }}
      />
      <canvas className={styles['canvas-rect']} ref={canvasRef} />
    </div>
  );
}

export default QRScannerControl;
