import { addHours, addMinutes, addSeconds } from '@sqior/js/data';
import { useCustomTimer, useTimer } from '@sqior/react/state';
import { classes, getPrettyTimeOnlyString, getPrettyTimeString } from '@sqior/react/utils';
import styles from './demo-time-control.module.css';
import { useState } from 'react';

export function DemoTimeControl() {
  const timer = useTimer();
  const time = useCustomTimer(200);
  const [showControls, setShowControls] = useState(true);

  function manipulate(ms: number) {
    const newNow = timer.timer.now + ms;
    timer.setTimerValue(newNow);
  }
  function toggleVisibility() {
    setShowControls(() => !showControls);
  }

  return (
    <div className={styles['container']}>
      {showControls && (
        <>
          <div onClick={() => manipulate(addHours(-1))}>-1h</div>
          <div onClick={() => manipulate(addMinutes(-5))}>-5m</div>
          <div onClick={() => manipulate(addMinutes(-1))}>-1m</div>
          <div onClick={() => manipulate(addSeconds(-10))}>-10s</div>
        </>
      )}
      <div
        className={classes(showControls ? undefined : styles['almost-invisible'])}
        onClick={() => toggleVisibility()}
      >
        {getPrettyTimeOnlyString(time, true)}
      </div>
      {showControls && (
        <>
          <div onClick={() => manipulate(addSeconds(10))}>+10s</div>
          <div onClick={() => manipulate(addMinutes(1))}>+1m</div>
          <div onClick={() => manipulate(addMinutes(5))}>+5m</div>
          <div onClick={() => manipulate(addHours(1))}>+1h</div>
        </>
      )}
    </div>
  );
}
