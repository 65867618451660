import { PluginContext } from '@sqior/js/plugin';
import { FactoryContext } from '@sqior/react/factory';
import { UIVisualPlugin } from '@sqior/react/uivisual';
import {
  BedAllocationDashboardType,
  BedAllocationInFlightPatientInfoType,
  BedAllocationListItemType,
  BedAllocationOverviewType,
  BedAllocationRequestParametersInfoType,
  BedAllocationRequestParametersInputType,
  BedAllocationTargetWardInputType,
  BedAllocationWardListItemType,
  OccupancyDashboardType,
  PatientDischargeOverviewType,
  WardOverviewDashboardType,
  WardSpecialtiesChartInfoType,
  WardSpecialtiesStatsInfoType,
  WardSPIAndSpecialtiesInfoType,
  WardSPIStatsInfoType,
} from '@sqior/viewmodels/occupancy';
import { lazy } from 'react';
import BedAllocationListItem from './bed-allocation-list-item/bed-allocation-list-item';
import BedAllocationWardListItem from './bed-allocation-ward-list-item/bed-allocation-ward-list-item';

const OccupancyDashboard = lazy(() =>
  import('../bundle').then((module) => ({ default: module.OccupancyDashboard }))
);

const BedAllocationParameterInputArea = lazy(() =>
  import('../bundle').then((module) => ({ default: module.BedAllocationParameterInputArea }))
);
const BedAllocationDashboard = lazy(() =>
  import('../bundle').then((module) => ({ default: module.BedAllocationDashboard }))
);
const BedAllocationInFlightPatientInfo = lazy(() =>
  import('../bundle').then((module) => ({ default: module.BedAllocationInFlightPatientInfo }))
);

const BedAllocationOverview = lazy(() =>
  import('../bundle').then((module) => ({ default: module.BedAllocationOverview }))
);
const BedAllocationParameterInfo = lazy(() =>
  import('../bundle').then((module) => ({ default: module.BedAllocationParameterInfo }))
);
const BedAllocationTargetWardInputArea = lazy(() =>
  import('../bundle').then((module) => ({ default: module.BedAllocationTargetWardInputArea }))
);

const WardOverviewDashboard = lazy(() =>
  import('../bundle').then((module) => ({ default: module.WardOverviewDashboard }))
);

const WarnSpecialtiesChart = lazy(() =>
  import('../bundle').then((module) => ({ default: module.WarnSpecialtiesChart }))
);

const WarnSpiChart = lazy(() =>
  import('../bundle').then((module) => ({ default: module.WarnSpiChart }))
);
const WardSpecialtiesStats = lazy(() =>
  import('../bundle').then((module) => ({ default: module.WardSpecialtiesStats }))
);
const CombinedSPIAndSpecialties = lazy(() =>
  import('../bundle').then((module) => ({ default: module.CombinedSPIAndSpecialties }))
);

export function UIOccupancyPlugin(context: PluginContext & FactoryContext) {
  context.plugins.require(UIVisualPlugin, context);
  context.factory.add(BedAllocationRequestParametersInputType, BedAllocationParameterInputArea);
  context.factory.add(BedAllocationTargetWardInputType, BedAllocationTargetWardInputArea);
  context.factory.add(BedAllocationOverviewType, BedAllocationOverview);
  context.factory.add(BedAllocationListItemType, BedAllocationListItem);
  context.factory.add(BedAllocationWardListItemType, BedAllocationWardListItem);
  context.factory.add(OccupancyDashboardType, OccupancyDashboard);
  context.factory.add(WardOverviewDashboardType, WardOverviewDashboard);
  context.factory.add(WardSpecialtiesChartInfoType, WarnSpecialtiesChart);
  context.factory.add(WardSpecialtiesStatsInfoType, WardSpecialtiesStats);
  context.factory.add(WardSPIStatsInfoType, WarnSpiChart);
  // context.factory.add(WardSPIStatsInfoType, WardSpiStats);
  context.factory.add(BedAllocationDashboardType, BedAllocationDashboard);
  context.factory.add(BedAllocationInFlightPatientInfoType, BedAllocationInFlightPatientInfo);
  context.factory.add(BedAllocationRequestParametersInfoType, BedAllocationParameterInfo);
  context.factory.add(PatientDischargeOverviewType, BedAllocationOverview);
  context.factory.add(WardSPIAndSpecialtiesInfoType, CombinedSPIAndSpecialties);
}
