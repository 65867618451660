import { classes, ZIndex } from '@sqior/react/utils';
import ButtonClose from '../button-close/button-close';
import PopupPage from '../popup-page/popup-page';
import styles from './closable-page.module.css';
import { useOnCancel } from '../use-on-cancel';

/* eslint-disable-next-line */
export interface ClosablePageProps {
  children: React.ReactNode | React.ReactNode[];
  onClose: () => void;
  className?: string;
  zIndex?: ZIndex;
  actions?: React.ReactNode;
  header?: React.ReactNode;
  hideHeader?: boolean;
  title?: React.ReactNode;
}

export function ClosablePage(props: ClosablePageProps) {
  useOnCancel(props.onClose);

  const getMarginTop = () => (props.hideHeader ? 0 : 6);

  return (
    <PopupPage className={classes(styles['container'], props.className)} zIndex={props.zIndex}>
      <div className={styles['header']} style={{ marginTop: getMarginTop() }}>
        {!props.header && !props.hideHeader && (
          <>
            <div
              className={styles['header-container']}
              style={{ width: props?.title ? '100%' : undefined }}
            >
              <div className={styles['title']}>{props?.title}</div>

              <div className={styles['action-buttons']}>
                {props?.actions}
                <ButtonClose additionalClassName={styles['btn-close']} onClick={props.onClose} />
              </div>
            </div>

            {props.children instanceof Array && (
              <div className={styles['header-content']}>{props.children[0]}</div>
            )}
          </>
        )}
        {props.header && !props.hideHeader && <>{props.header}</>}
      </div>

      {props.children instanceof Array ? props.children.slice(1) : props.children}
    </PopupPage>
  );
}

export default ClosablePage;
