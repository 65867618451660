import { EntityHeader } from '@sqior/js/entity';

export const GraphEdgeDataTypeCount = 'GraphEdgeDataTypeCount';
export const GraphEdgeDataTypeStatistic = 'GraphEdgeDataTypeStatistic';
export const GraphNodeDataTypeWourkflowCount = 'GraphNodeDataTypeWourkflowCount';

/**A process graph representation */
export type ProcessGraph = {
  nodes: Node[];
  edges: Edge[];
  areas: Area[];
  meta: Meta;
  transitionHistory: TransitionHistory[];
};

export function makeProcessGraph(init?: {
  nodes?: Node[];
  edges?: Edge[];
  areas?: Area[];
  meta?: Meta;
}): ProcessGraph {
  return {
    nodes: init?.nodes ?? [],
    edges: init?.edges ?? [],
    areas: init?.areas ?? [],
    meta: init?.meta ?? {},
    transitionHistory: [],
  };
}

/**A logical position
 * A logic position only describes  the position relatively between different position, but it does not describe an absoluty alignment.
 */
export type LogicalPosition = {
  h: number;
  v: number;
};
export function makeLogicalPosition(h: number, v: number) {
  return { h, v };
}

export type Coordinate = {
  x: number;
  y: number;
};

export enum NodeTypes {
  Generic = 'generic',
  SurgeonCall = 'SurgeonCall',
}

export enum StyleType {
  IncisionSuture = 'incision-suture',
  Anesthesia = 'anesthesia',
}

/** Describes a nodes in a graph */
export type Node = {
  /** The node id, must be unique within a graph*/
  id: string;
  /**The name of the node, expected to be displayed to the user */
  name: string;
  /** The node type */
  type: NodeTypes;
  /** Style information, used for specific visualization */
  style?: StyleType;
  /** Logical position how the nodes shall be placed and aligned */
  position?: LogicalPosition;
  fixPosition?: Coordinate;

  data?: {
    openTasks?: number;
    workflows?: number;
  };
};
export function makeNode(
  id: string,
  name: string,
  position?: LogicalPosition,
  nodeStyle?: StyleType,
  type?: NodeTypes
): Node {
  const node: Node = { id, name, type: type ? type : NodeTypes.Generic };
  if (position) node.position = position;
  if (nodeStyle) node.style = nodeStyle;
  return node;
}

export type Meta = {
  numProcesses?: number;
  maxCountEdgeDurationData?: number;
};

/** Describes an area in a graph
 * An area can be visualized as e.g. a rectangle surrounding the specified nodes.
 */
export type Area = {
  /** The id of the area, must be unique within a graph*/
  id: string;
  /**The name of the area, expected to be displayed to the user */
  name: string;
  /** Style information, used for specific visualization */
  style?: StyleType;
  /**The nodes that are part of the area */
  nodes: string[];
};
export function makeArea(id: string, name: string, nodes: string[]) {
  return { id, name, nodes };
}

export enum EdgeDirection {
  UniDirectional = 'unidirectional',
  BiDirectional = 'bidirectional',
}

/** Describes an edge in a graph */
export type Edge = {
  /** The edge id, must be unique within a graph*/
  id: string;
  /** The id of the source node this edge represents */
  sourceId: string;
  /** The id of the target node this edge represents */
  targetId: string;
  /** The direction type of the edge. If not specified, EdgeDirection.UniDirectional is assumed */
  direction?: EdgeDirection;
  /** The relative weight of the edge [0..1] */
  weight?: number;
  /** Style information, used for specific visualization */
  style?: StyleType | [StyleType, StyleType];
  /** Style information, used for specific visualization */
  name?: string;
  /** Label information to display */
  label?: EdgeDataType;
};

export function makeEdge(
  id: string,
  sourceId: string,
  targetId: string,
  label?: EdgeDataType,
  optional?: {
    direction?: EdgeDirection;
    weight?: number;
    style?: StyleType | [StyleType, StyleType];
    name?: string;
  }
): Edge {
  const edge: Edge = {
    id,
    sourceId,
    targetId,
  };
  if (label) edge.label = label;
  if (optional?.direction) edge.direction = optional.direction;
  if (optional?.weight) edge.weight = optional.weight;
  if (optional?.style) edge.style = optional.style;
  if (optional?.name) edge.name = optional.name;
  return edge;
}

export type EdgeDataType = EntityHeader;
export type EdgeDataTypeCount = EdgeDataType & {
  count: number; // 12321
  percentage: number; // .32
};
export function makeEdgeLabelCount(count: number, percentage: number): EdgeDataTypeCount {
  return {
    entityType: GraphEdgeDataTypeCount,
    count,
    percentage,
  };
}

export type EdgeDataTypeStatistic = EdgeDataTypeCount & {
  average: string; // 15 min, 5:20 min, 39 s
  deviation: string; // +- deviation , +- 20 s, +- 1:30 min
};
export function makeEdgeLabelStatistic(
  average: string,
  deviation: string,
  count: number,
  percentage: number
): EdgeDataTypeStatistic {
  return {
    entityType: GraphEdgeDataTypeStatistic,
    average,
    deviation,
    count,
    percentage,
  };
}

export type NodeDataTypeWorkflowCount = EntityHeader & {
  workflows: number;
  openTasks: number;
};

export function makeNodeDataWorkflowCount(
  countWorkflows: number,
  countOpenTasks: number
): NodeDataTypeWorkflowCount {
  return {
    entityType: GraphNodeDataTypeWourkflowCount,
    workflows: countWorkflows,
    openTasks: countOpenTasks,
  };
}

export type TransitionHistory = {
  timestamp: number;
  id: string;
  from: string;
  to: string;
};
