import { StateOverlayContext } from '@sqior/js/state-operation';
import { FactoryContext } from '@sqior/react/factory';
import { MessengerPages, MessengerTabOverlay, MessengerTabPath } from '@sqior/viewmodels/app';
import {
  DefaultLocationOverlay,
  DefaultLocationPath,
  FixedPushDevicesOverlay,
  FixedPushDevicesPath,
  PhoneNumberSettingOverlay,
  PhoneNumberSettingPath,
} from '@sqior/viewmodels/user';
import CommandHistoryPage from './command-history-page/command-history-page';
import SettingsPage from './settings-page/settings-page';
import QRRoomPage from './qrpage/qrroompage';
import { QRUserPage } from './qrpage/qrpage';
import SpecialtySelectionPage from './specialty-selection-page/specialty-selection-page';
import SupportInbox from './support-inbox/support-inbox';
import NewSupportChat from './new-support-chat/new-support-chat';
import ServerInfoPage from './server-info-page/server-info-page';

export function UIMessengerPlugin(context: StateOverlayContext & FactoryContext) {
  context.stateOverlays.add(DefaultLocationPath, DefaultLocationOverlay);
  context.stateOverlays.add(FixedPushDevicesPath, FixedPushDevicesOverlay);
  context.stateOverlays.add(PhoneNumberSettingPath, PhoneNumberSettingOverlay);
  context.stateOverlays.add(MessengerTabPath, MessengerTabOverlay);

  context.factory.add(MessengerPages.CommandHistory, CommandHistoryPage);
  context.factory.add(MessengerPages.Settings, SettingsPage);
  context.factory.add(MessengerPages.ChoseRoom, QRRoomPage);
  context.factory.add(MessengerPages.ChoseSpecialty, SpecialtySelectionPage);
  context.factory.add(MessengerPages.QRScan, QRUserPage);
  context.factory.add(MessengerPages.SupportInbox, SupportInbox);
  context.factory.add(MessengerPages.NewSupportChat, NewSupportChat);
  context.factory.add(MessengerPages.ServerInfo, ServerInfoPage);
}
