import { CacheCleanup, CacheState, CacheStateType, CombinedCacheState } from '@sqior/js/cache';
import {
  addHours,
  ArraySource,
  ensureArray,
  isEqual,
  KeyPairMap,
  StdTimer,
  TimerInterface,
} from '@sqior/js/data';
import { Entity } from '@sqior/js/entity';
import { Logger } from '@sqior/js/log';
import { EntityRecord } from './entity';
import { EntityMappingCache, EntityMappingCacheResult } from './entity-mapping-cache';
import {
  mappingContextKeysAdd,
  mappingContextKeysClone,
  mappingContextKeysToArray,
} from './entity-mapping-context-keys';
import {
  Closable,
  DeadlockAccessor,
  DeadlockDetector,
  applyAfter,
  applyAfterEval,
} from '@sqior/js/async';
import { ContextPropertyModel } from './context-property';
import { Models } from './models';

export type EntityMappingTrace = { accessors?: DeadlockAccessor[] };
type EntityMapFunc = (
  entity: Entity,
  context: EntityRecord,
  trace: EntityMappingTrace
) => Promise<EntityMappingCacheResult> | EntityMappingCacheResult;
type SyncEntityMapFunc = (
  entity: Entity,
  context: EntityRecord,
  trace: EntityMappingTrace
) => EntityMappingCacheResult;
type MapEntry = {
  func: EntityMapFunc;
  sync?: SyncEntityMapFunc;
  trivial: boolean;
  essentialContext?: Set<string>;
};
type RouteEntry = { weight: number; path?: string[]; map?: MapEntry };

export class EntityMapping implements Closable {
  constructor(
    models: Models,
    contextProperties: Map<string, ContextPropertyModel>,
    timer: TimerInterface = new StdTimer()
  ) {
    this.models = models;
    this.contextProperties = contextProperties;
    this.cache = new EntityMappingCache(new CacheCleanup(addHours(1), timer));
  }

  /** Deactivates by closing the cache */
  async close() {
    await this.cache.close();
  }

  /** Creates a mapping that optionally determines the value from cache and caches the result */
  private makeCachingMapping(
    from: string,
    to: string,
    mapping: EntityMapFunc,
    essentialContext?: Set<string>
  ): EntityMapFunc {
    /* Check if the source type is cacheable at all */
    if (!this.models.get(from).keyable) return mapping;
    return (entity: Entity, context: EntityRecord, trace: EntityMappingTrace) => {
      /* Calculate key for cache */
      const key = this.models.key(entity);
      /* Convert the values from the context to keys, only consider the essential context (= declared non-auto-forwarded properties)
         and auto-forwarded properties */
      const cacheContext: Record<string, string> = {};
      for (const contextKey in context)
        if (
          this.contextProperties.get(contextKey)?.autoForward ||
          essentialContext?.has(contextKey)
        )
          cacheContext[contextKey] = this.models.key(context[contextKey]);
      /* Try to look up from cache */
      const cacheRes = this.cache.get(key, cacheContext, to);
      if (typeof cacheRes !== 'string') {
        /* Check if a preliminary cache entry is hit, if yes register for deadlock prevention */
        if (cacheRes instanceof Array) {
          /* Register access to preliminary cache key, if this has already registered */
          if (trace.accessors) {
            const blockingChain = this.deadlockDetector.checkAccess(
              cacheRes[1] + '|>' + to,
              trace.accessors
            );
            if (blockingChain) {
              Logger.debug([
                'Circular deadlock detected in mapping from:',
                cacheRes[1],
                'to:',
                to,
                'trace:',
                trace.accessors
                  .map((acc) => {
                    return acc.id;
                  })
                  .join(', '),
                'chain:',
                blockingChain
                  .map((acc) => {
                    return acc.id;
                  })
                  .join(', '),
              ]);
              throw new Error('Deadlock detected in mapping from: ' + from + ' to: ' + to);
            }
          }
          /* Determine cache value */
          return applyAfter(cacheRes[0], EntityMappingCache.incCacheUse, undefined, () => {
            /* Release access */
            if (trace.accessors) this.deadlockDetector.releaseAccess(trace.accessors);
          });
        } else return EntityMappingCache.incCacheUse(cacheRes);
      }
      /* Register access to preliminary cache key */
      const id = cacheRes + '|>' + to;
      const accessor = { id };
      const release = this.deadlockDetector.register(accessor, id);
      if (!release) {
        Logger.debug([
          'Deadlock detected in mapping from:',
          cacheRes,
          'to:',
          to,
          'trace:',
          ensureArray(trace.accessors)
            .map((acc) => {
              return acc.id;
            })
            .join(', '),
        ]);
        throw new Error('Deadlock detected in mapping from: ' + from + ' to: ' + to);
      }
      /* Evaluate the mapping and cache the promise */
      const finalizer = (exc: unknown) => {
        /* Release cache access */
        release();
        if (exc !== undefined)
          Logger.debug([
            'Unexpected exception when evaluating cache for:',
            entity.entityType,
            'to:',
            to,
            '- exception:',
            Logger.exception(exc),
          ]);
      };
      try {
        return this.cache.set(
          key,
          cacheRes,
          cacheContext,
          to,
          mapping(entity, context, {
            accessors: trace.accessors ? [...trace.accessors, accessor] : [accessor],
          }),
          finalizer
        );
      } catch (exc) {
        finalizer(exc);
        throw exc;
      }
    };
  }

  /** Creates a mapping that does not invalidate the result immediately but rather re-evaluates
   *  and only invalidates once the value is different from the original */
  private static makeValueComparisonMapping(
    models: Models,
    cache: EntityMappingCache,
    mapping: EntityMapFunc,
    to: string
  ) {
    return async (entity: Entity, context: EntityRecord, trace: EntityMappingTrace) => {
      /* Evaluate the mapping */
      const res = await mapping(entity, context, trace);
      /* If the mapping is permanently valid, return it */
      if (!res.cache || (res.cache.valid && !res.cache.invalidated)) return res;
      /* Calculate key for cache */
      const key = models.key(entity);
      /* Convert the values from the context to keys */
      const cacheContext: Record<string, string> = {};
      for (const contextKey in context) cacheContext[contextKey] = models.key(context[contextKey]);
      /* Create an own cache state */
      const cacheState = new CacheState(CacheStateType.Closable);
      EntityMapping.handleValueComparisonResult(
        res,
        () => {
          return mapping(entity, context, trace);
        },
        cacheState,
        (res: EntityMappingCacheResult) => {
          /* Set cache entry */
          cache.setFinal(key, cacheContext, to, res);
        }
      );
      return { ...res, cache: cacheState };
    };
  }
  private static handleValueComparisonResult(
    orig: EntityMappingCacheResult,
    recalculate: () => Promise<EntityMappingCacheResult> | EntityMappingCacheResult,
    cacheState: CacheState,
    setCache: (res: EntityMappingCacheResult) => void
  ) {
    /* Define what needs to be done if the result is invalid */
    const onInvalid = () => {
      orig.cache?.decRef();
      if (cacheState.refCount)
        applyAfter(
          recalculate(),
          (res) => {
            /* Check if result changed */
            if (
              !isEqual(res.result, orig.result) ||
              !isEqual(
                mappingContextKeysToArray(res.contextKeys),
                mappingContextKeysToArray(orig.contextKeys)
              )
            ) {
              cacheState.invalidate();
              setCache({ ...res, cache: (cacheState = new CacheState(CacheStateType.Closable)) });
            }
            EntityMapping.handleValueComparisonResult(res, recalculate, cacheState, setCache); // Recurse for this result
          },
          () => {
            cacheState.invalidate();
          }
        );
    };
    /* Either trigger immediately or if invalid */
    if (orig.cache?.valid === false) onInvalid();
    else if (orig.cache?.invalidated) {
      let stopInvalid: (() => void) | undefined = undefined;
      const stopClose = cacheState.closed?.on(() => {
        stopInvalid?.();
        orig.cache?.decRef();
      });
      stopInvalid = orig.cache.invalidated.on(() => {
        stopClose?.();
        onInvalid();
      });
    } else orig.cache?.decRef();
  }

  /** Extracts the non-auto-forwarded context parameters */
  private essentialContext(props?: ArraySource<string>): Set<string> | undefined {
    let context: Set<string> | undefined;
    if (props)
      for (const prop of ensureArray(props)) {
        const meta = this.contextProperties.get(prop);
        if (!meta)
          throw new Error('Mapping registered with referencing unknown context property: ' + prop);
        if (meta.autoForward) continue;
        if (!context) context = new Set<string>([prop]);
        else context.add(prop);
      }
    return context;
  }

  /** Adds a asynchronous mapping connected two types */
  addSync(
    from: string,
    to: string,
    mapping: SyncEntityMapFunc,
    options: {
      context?: string | string[];
      weight?: number;
      trivial?: boolean;
    }
  ) {
    this.directMappings.set(from, to, [
      options.weight ?? 1,
      {
        func: mapping,
        sync: mapping,
        trivial: options.trivial ?? false,
        essentialContext: this.essentialContext(options.context),
      },
    ]);
  }

  /** Adds an (asynchronous) mapping connected two types */
  add(
    from: string,
    to: string,
    mapping: EntityMapFunc,
    options: {
      context?: string | string[];
      weight?: number;
      cache?: boolean;
      valueComparison?: boolean;
    }
  ) {
    /* Reduce the context to the non-auto-forwarded properties */
    const essentialContext = this.essentialContext(options.context);
    /* Establish a caching mapping if the input is not trivial */
    if (options.cache)
      mapping = this.makeCachingMapping(
        from,
        to,
        options.valueComparison
          ? EntityMapping.makeValueComparisonMapping(this.models, this.cache, mapping, to)
          : mapping,
        essentialContext
      );
    this.directMappings.set(from, to, [
      options.weight ?? 1,
      { func: mapping, trivial: false, essentialContext },
    ]);
  }

  mapEntity(
    entity: Entity,
    type: string,
    context: EntityRecord,
    trace: EntityMappingTrace
  ): EntityMappingCacheResult | Promise<EntityMappingCacheResult> {
    /* Check if input type is identical to the target type */
    if (entity.entityType === type) return { result: entity };
    /* Check if this can be mapped */
    let mapper: MapEntry | undefined;
    try {
      mapper = this.ensureMapping(entity.entityType, type);
    } catch (e) {
      Logger.error([
        'Unexpected exception when calculating map route from:',
        entity.entityType,
        'to:',
        type,
        '- exception:',
        Logger.exception(e),
      ]);
      throw e;
    }
    if (mapper) {
      const theMapper = mapper;
      return applyAfterEval(
        () => {
          return theMapper.func(entity, context, trace);
        },
        (a) => {
          return a;
        },
        (e) => {
          Logger.error([
            'Unexpected exception when mapping from:',
            entity.entityType,
            'to:',
            type,
            '- exception:',
            Logger.exception(e),
          ]);
          throw e;
        }
      );
    }
    return {};
  }

  private findRoute(from: string, to: string, forceEntry = true): RouteEntry {
    /* Check if route is already known */
    const exRoute = this.mappings.get(from, to);
    if (exRoute) return exRoute;
    /* Make a temporary entry for breaking circles */
    this.mappings.set(from, to, { weight: 0 });
    /* Make a depth first search */
    const route: RouteEntry = { weight: 0 };
    let crossTemp = false;
    /* Check if we have hit a direct connection, do not recurse in this case */
    for (const intermediate of this.directMappings.map.get(from) || [])
      if (intermediate[0] !== to) {
        /* Ensure the mapping to the intermediate type */
        const secondLeg = this.findRoute(intermediate[0], to, false);
        if (!secondLeg.path) {
          crossTemp = true;
          continue;
        }
        if (secondLeg.path.length === 0) continue;
        const firstLeg = this.findRoute(from, intermediate[0], false);
        if (!firstLeg.path) {
          crossTemp = true;
          continue;
        }
        if (firstLeg.path.length === 0) continue;
        /* Do not follow this route if the weight is not below the current minimum */
        const weight = firstLeg.weight + secondLeg.weight;
        if (route.path && weight >= route.weight) {
          if (weight === route.weight && !isEqual(firstLeg.path.concat(secondLeg.path), route.path))
            Logger.warn([
              'Ambiguous sum of weights in entity mapping from:',
              from,
              'provided:',
              firstLeg.path.concat(secondLeg.path),
              'vs:',
              route.path,
            ]);
          continue;
        }
        route.weight = weight;
        route.path = firstLeg.path.concat(secondLeg.path);
      } else if (!route.path || intermediate[1][0] <= route.weight) {
        if (intermediate[1][0] === route.weight)
          Logger.warn([
            'Ambiguous sum of weights in entity mapping:',
            from,
            'to:',
            to,
            'vs:',
            route.path || '',
          ]);
        route.weight = intermediate[1][0];
        route.path = [to];
      }

    /* Do not remember routing decision on sub-levels if a temporary entry was crossed */
    if (forceEntry || !crossTemp) {
      if (!route.path) route.path = [];
      this.mappings.set(from, to, route);
    } else this.mappings.delete(from, to);

    return route;
  }

  /** Combineds two mappings results */
  private combinedMappingResults(
    first: EntityMappingCacheResult,
    second: EntityMappingCacheResult
  ) {
    return {
      result: second.result,
      cache: CombinedCacheState.combine(first.cache, second.cache),
      contextKeys: mappingContextKeysAdd(
        mappingContextKeysClone(second.contextKeys),
        first.contextKeys
      ),
    };
  }

  /** Creates a combined mappings from two legs */
  private combineMappings(from: string, to: string, first: MapEntry, second: MapEntry): MapEntry {
    /* Check if the second leg is a trivial mapping, if yes we can simply use the first leg as the complete route */
    if (second.trivial) return first;
    /* Check if the first leg is trivial */
    if (first.trivial)
      if (second.sync)
        // Check if the second leg is synchronous
        return second;
      else
        return {
          func: this.makeCachingMapping(from, to, second.func, second.essentialContext),
          trivial: false,
          essentialContext: second.essentialContext,
        };
    /* Combine the contexts */
    let essentialContext = first.essentialContext;
    if (!essentialContext) essentialContext = second.essentialContext;
    else if (second.essentialContext)
      essentialContext = new Set<string>(
        [...essentialContext.keys()].concat(...second.essentialContext.keys())
      );
    /* Check if the first leg is synchronous */
    const firstSync = first.sync;
    if (firstSync) {
      /* Check if the second leg is synchronous */
      const secondSync = second.sync;
      if (secondSync) {
        const func = (
          entity: Entity,
          context: EntityRecord,
          trace: EntityMappingTrace
        ): EntityMappingCacheResult => {
          /* Evaluate the first mapping */
          const firstRes = firstSync(entity, context, trace);
          if (!firstRes.result) return firstRes;
          /* Evaluate the remaining mapping */
          const res = secondSync(firstRes.result, context, trace);
          return this.combinedMappingResults(firstRes, res);
        };
        return { func, sync: func, trivial: false, essentialContext };
      } else
        return {
          func: this.makeCachingMapping(
            from,
            to,
            (
              entity: Entity,
              context: EntityRecord,
              trace: EntityMappingTrace
            ): EntityMappingCacheResult | Promise<EntityMappingCacheResult> => {
              /* Evaluate the first mapping */
              const firstRes = firstSync(entity, context, trace);
              if (!firstRes.result) return firstRes;
              /* Evaluate the remaining mapping */
              return applyAfter<EntityMappingCacheResult, EntityMappingCacheResult>(
                second.func(firstRes.result, context, trace),
                (res) => {
                  /* Combine cache states */
                  return this.combinedMappingResults(firstRes, res);
                }
              );
            },
            essentialContext
          ),
          trivial: false,
          essentialContext,
        };
    }
    /* Check if the second leg is synchronous */
    const secondSync = second.sync;
    if (secondSync)
      return {
        func: this.makeCachingMapping(
          from,
          to,
          (
            entity: Entity,
            context: EntityRecord,
            trace: EntityMappingTrace
          ): EntityMappingCacheResult | Promise<EntityMappingCacheResult> => {
            /* Evaluate the first mapping */
            const firstRes = first.func(entity, context, trace);
            if (firstRes instanceof Promise)
              return firstRes.then((intRes) => {
                if (!intRes.result) return intRes;
                /* Evaluate the remaining mapping */
                const res = secondSync(intRes.result, context, trace);
                /* Combine cache states */
                return this.combinedMappingResults(intRes, res);
              });
            if (!firstRes.result) return firstRes;
            /* Evaluate the remaining mapping */
            const res = secondSync(firstRes.result, context, trace);
            /* Combine cache states */
            return this.combinedMappingResults(firstRes, res);
          },
          essentialContext
        ),
        trivial: false,
        essentialContext,
      };

    /* Combine two pot. asynchronous mappings*/
    return {
      func: this.makeCachingMapping(
        from,
        to,
        (
          entity: Entity,
          context: EntityRecord,
          trace: EntityMappingTrace
        ): EntityMappingCacheResult | Promise<EntityMappingCacheResult> => {
          /* Evaluate the first mapping */
          const firstRes = first.func(entity, context, trace);
          if (firstRes instanceof Promise)
            return firstRes.then((intRes) => {
              if (!intRes.result) return intRes;
              /* Evaluate the remaining mapping */
              return applyAfter<EntityMappingCacheResult, EntityMappingCacheResult>(
                second.func(intRes.result, context, trace),
                (res) => {
                  /* Combine cache states */
                  return this.combinedMappingResults(intRes, res);
                }
              );
            });
          if (!firstRes.result) return firstRes;
          /* Evaluate the remaining mapping */
          return applyAfter<EntityMappingCacheResult, EntityMappingCacheResult>(
            second.func(firstRes.result, context, trace),
            (res) => {
              /* Combine cache states */
              return this.combinedMappingResults(firstRes, res);
            }
          );
        },
        essentialContext
      ),
      trivial: false,
      essentialContext,
    };
  }

  private ensureMapping(from: string, to: string): MapEntry | undefined {
    /* (Try to) find a route */
    const route = this.findRoute(from, to);
    if (!route.path || route.path.length === 0) return undefined;
    if (!route.map)
      if (route.path.length > 1) {
        /* Check if this is a direct mapping */
        /* Call recursively */
        const intermediate = route.path[route.path.length - 2];
        const firstLeg = this.ensureMapping(from, intermediate);
        /* Get the final step */
        const secondLeg = this.directMappings.get(intermediate, route.path[route.path.length - 1]);
        if (!firstLeg || !secondLeg)
          throw new Error(
            'Inconsistency in mapping from: ' +
              from +
              ' to: ' +
              route.path[route.path.length - 1] +
              ' via: ' +
              intermediate
          );
        route.map = this.combineMappings(from, to, firstLeg, secondLeg[1]);
      } else {
        const mapEntry = this.directMappings.get(from, route.path[0]);
        if (!mapEntry)
          throw new Error('Inconsistency in mapping from: ' + from + ' to: ' + route.path[0]);
        route.map = mapEntry[1];
      }
    return route.map;
  }

  canBeMapped(from: string, to: string) {
    const route = this.findRoute(from, to);
    return route.path ? route.path.length > 0 : false;
  }
  canBeMappedTrivially(from: string, to: string) {
    const mapping = this.ensureMapping(from, to);
    return mapping ? mapping.trivial : false;
  }

  private models: Models;
  private contextProperties: Map<string, ContextPropertyModel>;
  private directMappings = new KeyPairMap<string, string, [number, MapEntry]>();
  private mappings = new KeyPairMap<string, string, RouteEntry>();
  private deadlockDetector = new DeadlockDetector();
  readonly cache;
}
