import { UserInfo } from '@sqior/js/authbase';
import { addMinutes } from '@sqior/js/data';
import { StopListening } from '@sqior/js/event';
import { Logger } from '@sqior/js/log';
import { BiChannel } from '@sqior/js/message';
import { UID } from '@sqior/js/uid';
import { UserConnection, UserSession } from './session';
import { ConnectionMessageType } from './session-message';

/** Information kept per connection */
type ConnectionInfo<ConnectionType> = {
  connection: ConnectionType;
  timer?: ReturnType<typeof setTimeout>;
  openList?: StopListening;
  closeList?: StopListening;
  logOut: boolean;
  logOutList?: StopListening;
  device?: string;
};

export const UserConnectionTimeout = addMinutes(5);
export const UserSessionTimeout = addMinutes(90);

export interface SessionStoreInterface {
  findOrCreate(sessionId: UID, user: UserInfo, channel: BiChannel): UserConnection;
}

export class SessionStore<
  UserSessionType extends UserSession,
  UserConnectionType extends UserConnection
> implements SessionStoreInterface
{
  constructor(
    userSessionCreator: (user: UserInfo) => UserSessionType,
    userConnectionCreator: (channel: BiChannel, userSession: UserSessionType) => UserConnectionType
  ) {
    this.userSessionCreator = userSessionCreator;
    this.userConnectionCreator = userConnectionCreator;
  }

  private getUserId(user: UserInfo) {
    return user.iss + ':' + user.sub + (user.subUserId ? ':' + user.subUserId : '');
  }

  private stopWatch(ci: ConnectionInfo<UserConnectionType>) {
    /* Stop the previous listeners, if applicable */
    if (ci.openList) {
      ci.openList();
      ci.openList = undefined;
    }
    if (ci.closeList) {
      ci.closeList();
      ci.closeList = undefined;
    }
    /* Delete a pot. running timer */
    if (ci.timer) {
      clearTimeout(ci.timer);
      ci.timer = undefined;
    }
  }

  private watchChannel(ci: ConnectionInfo<UserConnectionType>, channel: BiChannel) {
    /* Stop a pot. previous watch for this connection */
    this.stopWatch(ci);
    /* Wait for the disconnection */
    let reportClosed = channel.in.isOpen;
    ci.closeList = channel.in.onClose(() => {
      if (reportClosed)
        Logger.debug(`Disconnect of connection, connection id: ${ci.connection.connectionId}`);
      reportClosed = true;
      /* Start a timer that cleans up the connection after some time, unless the user explictly logged out */
      if (ci.logOut) this.removeClient(ci.connection, true);
      else
        ci.timer = setTimeout(() => {
          ci.timer = undefined;
          this.removeClient(ci.connection, false);
        }, UserConnectionTimeout);
    });
    /* Wait for a connection to stop the timer */
    ci.openList = channel.in.onOpen(() => {
      if (ci.timer) {
        clearTimeout(ci.timer);
        ci.timer = undefined;
      }
    });
    /* Listen for the logout message */
    ci.logOutList = channel.in.on(ConnectionMessageType.LogOut, () => {
      ci.logOut = true;
    });
  }

  findOrCreate(sessionId: UID, user: UserInfo, channel: BiChannel): UserConnectionType {
    /* Check if this session already exists */
    let connectionInfo = this.userConnections.get(sessionId);
    if (connectionInfo) {
      /* Check if the user matches (= change of user within session) */
      if (this.getUserId(connectionInfo.connection.session.userInfo) === this.getUserId(user)) {
        /* Set new channel to existing connection */
        connectionInfo.connection.setChannelAndUserInfo(channel, user);
        this.watchChannel(connectionInfo, channel);
        return connectionInfo.connection;
      } else this.removeClient(connectionInfo.connection, true);
    }
    /* Try to find an existing user session */
    const userSessionId = this.getUserId(user);
    let sessionInfo = this.userSessions.get(userSessionId);
    if (!sessionInfo)
      /* Create a new user session */
      this.userSessions.set(
        userSessionId,
        (sessionInfo = {
          session: this.userSessionCreator(user),
          connections: new Set<UserConnectionType>(),
        })
      );
    else if (sessionInfo.timer !== undefined) {
      /* Stop clean up timer if applicable */
      clearTimeout(sessionInfo.timer);
      sessionInfo.timer = undefined;
    }
    /* Create a new connection object */
    const uc = this.userConnectionCreator(channel, sessionInfo.session);
    this.userConnections.set(uc.connectionId, (connectionInfo = { connection: uc, logOut: false }));
    sessionInfo.connections.add(uc);
    this.watchChannel(connectionInfo, channel);
    return uc;
  }

  /** Finds an user session */
  findSession(user: UserInfo): UserSessionType | undefined {
    const userSessionId = this.getUserId(user);
    const sessionInfo = this.userSessions.get(userSessionId);
    return sessionInfo?.session;
  }

  /** Assoicates a user connection with a device */
  setClientDevice(uc: UserConnectionType, device: string) {
    /* Check if this device is registered for another connection, if yes, then remove it */
    const existingConnection = this.deviceConnections.get(device);
    if (existingConnection && existingConnection !== uc) {
      Logger.debug([
        'Closing existing connection:',
        existingConnection.connectionId,
        'because same device:',
        device,
        'is now registered to a new connection:',
        uc.connectionId,
      ]);
      this.removeClient(existingConnection, true);
    }
    /* Remembering device */
    const info = this.userConnections.get(uc.connectionId);
    if (!info) {
      Logger.warn([
        'Trying to set device:',
        device,
        'for a user connection:',
        uc.connectionId,
        'which is not registered',
      ]);
      return;
    }
    info.device = device;
    this.deviceConnections.set(device, uc);
  }

  /** Cleans up a session */
  private removeSession(id: string, session: UserSessionType) {
    Logger.debug(
      ['Cleaning up user session:', id],
      ['Cleaning up user session:', id, 'for:', session.userInfo.sub]
    );
    /* Remove from session pool */
    this.userSessions.delete(id);
    /* Close services */
    session.close().catch((e) => {
      Logger.warn(['Exception when closing user session:', Logger.exception(e)]);
    });
  }

  private removeClient(uc: UserConnectionType, logOut: boolean) {
    /* Forget client */
    const info = this.userConnections.get(uc.connectionId);
    if (info) {
      /* Remove from device map, if applicable */
      if (info.device) this.deviceConnections.delete(info.device);
      /* Stop watch, if applicable */
      this.stopWatch(info);
    }
    Logger.debug(['Cleaning up user connection:', uc.connectionId]);
    this.userConnections.delete(uc.connectionId);
    /* Close the connection */
    uc.close(logOut).catch((e) => {
      Logger.warn(['Exception when closing user connection:', Logger.exception(e)]);
    });
    /* Decrease client count, start clean up timer if this was the last user connection */
    const userSessionId = this.getUserId(uc.session.userInfo);
    const sessionInfo = this.userSessions.get(userSessionId);
    if (!sessionInfo) return;
    sessionInfo.connections.delete(uc);
    if (sessionInfo.connections.size > 0) return;
    /* Start a timer to clean-up the session unless the last connection explictly logged out */
    if (logOut) this.removeSession(userSessionId, sessionInfo.session);
    else
      sessionInfo.timer = setTimeout(() => {
        this.removeSession(userSessionId, sessionInfo.session);
      }, UserSessionTimeout);
  }

  private userSessionCreator: (user: UserInfo) => UserSessionType;
  private userConnectionCreator: (
    channel: BiChannel,
    userSession: UserSessionType
  ) => UserConnectionType;

  readonly userSessions = new Map<
    UID,
    {
      session: UserSessionType;
      connections: Set<UserConnectionType>;
      timer?: ReturnType<typeof setTimeout>;
    }
  >();
  private userConnections = new Map<UID, ConnectionInfo<UserConnectionType>>();
  private deviceConnections = new Map<string, UserConnectionType>();
}
