import React from 'react';
import { Entity, EntityType } from '@sqior/js/entity';
import { ValueObject } from '@sqior/js/data';
import LazyLoading from './LazyLoading';

export type HasStatePath = {
  statePath?: string;
};

export type FactoryProps<Type extends Entity = Entity> = {
  data: Type;
  onClose?: (success?: boolean, data?: ValueObject, title?: string) => void;
} & HasStatePath &
  Record<string, unknown>;

// Update FactoryElement to be a component type instead of a callable function type
export type FactoryElement<Type extends FactoryProps<Entity> = FactoryProps<Entity>> =
  React.ComponentType<Type>;

/** Helper to get the additional parameters from FactoryProps */
export function factoryGetProp<Type>(name: string, props: FactoryProps) {
  if (name in props) return props[name] as Type;
  return undefined;
}

/** Empty components which can be registered at factor which renders nothing
 */
export function EmptyComponent(props: FactoryProps<Entity>) {
  return null;
}

export class Factory {
  add<Type extends FactoryProps<Entity>>(
    type: EntityType,
    component: React.ComponentType<Type> | (() => Promise<{ default: React.ComponentType<Type> }>)
  ) {
    // Check if the component is a lazy loader or a regular component
    const LazyOrRegularComponent = this.isLazyLoader(component)
      ? React.lazy(component as () => Promise<{ default: React.ComponentType<Type> }>)
      : (component as React.ComponentType<Type>);

    this.elements.set(type, LazyOrRegularComponent);
  }

  get(): FactoryElement {
    return (props: FactoryProps) => {
      const Component = this.elements.get(props.data.entityType);
      if (!Component)
        throw new Error('Element type is not registered in the factory: ' + props.data.entityType);
      // Render using JSX syntax
      return (
        <LazyLoading>
          <Component {...props} />
        </LazyLoading>
      );
    };
  }

  private elements = new Map<EntityType, React.ComponentType<any>>();

  // Helper function to determine if the component is a lazy loader
  private isLazyLoader<Type>(
    component: React.ComponentType<Type> | (() => Promise<{ default: React.ComponentType<Type> }>)
  ): component is () => Promise<{ default: React.ComponentType<Type> }> {
    return typeof component === 'function' && (component as any).toString().includes('Promise');
  }
}

export const ComponentFactory = React.createContext<FactoryElement>(() => {
  throw new Error('Factory not initialized');
});

export default Factory;
