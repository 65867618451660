import {
  ORWorkflowCountdownType,
  ORWorkflowIndicatorState,
  ORWorkflowIndicatorVM,
} from '@sqior/viewmodels/orworkflow';
import styles from './orworkflow-indicator-control.module.css';
import { ItemState } from '@sqior/viewmodels/visual';
import { CountdownTimer, TransportState } from '@sqior/react/uibase';
import { addMinutes } from '@sqior/js/data';

const getCountDownState = (stage: ORWorkflowCountdownType) => {
  switch (stage) {
    case ORWorkflowCountdownType.Anesthesia:
      return ItemState.Hot;
    case ORWorkflowCountdownType.Surgery:
      return ItemState.Highlighted;
    case ORWorkflowCountdownType.Emergency:
      return ItemState.Emergency;
  }
  return ItemState.Normal;
};

function transportState(stage: ORWorkflowIndicatorState) {
  return stage === ORWorkflowIndicatorState.TransportOrdered
    ? 'start'
    : stage === ORWorkflowIndicatorState.TransportRunning
    ? 'running'
    : stage === ORWorkflowIndicatorState.TransportCancelled
    ? 'stopped'
    : undefined;
}

export type ORWorkflowIndicatorControlProps = {
  indicator: ORWorkflowIndicatorVM;
};

export function ORWorkflowIndicatorControl(props: ORWorkflowIndicatorControlProps) {
  return (
    <div className={styles['container']}>
      {typeof props.indicator === 'object' && (
        <CountdownTimer
          className={styles['countdown-timer']}
          timeout={props.indicator.countdown ?? 0}
          duration={props.indicator.countdownDuration ?? addMinutes(60)}
          state={getCountDownState(props.indicator.countdownType)}
        />
      )}
      {typeof props.indicator === 'string' && (
        <TransportState state={transportState(props.indicator)} />
      )}
    </div>
  );
}

export default ORWorkflowIndicatorControl;
