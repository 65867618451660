import { Icon } from '@iconify/react';
import { Icons } from '@sqior/viewmodels/visual';
import { cloneElement, ReactElement } from 'react';
import { ReactComponent as Cancel } from './svg-icons/cancel.svg';
import { ReactComponent as ChangeDescription } from './svg-icons/change-description.svg';
import { ReactComponent as ChangeLocation } from './svg-icons/change-location.svg';
import { ReactComponent as Inserted } from './svg-icons/inserted.svg';
import { ReactComponent as Reordered } from './svg-icons/reordered.svg';
import { ReactComponent as EventBusy } from './svg-icons/event-busy.svg';
import { ReactComponent as Lock } from './svg-icons/lock.svg';
import { ReactComponent as Report } from './svg-icons/report.svg';
import { ReactComponent as EventAvailable } from './svg-icons/event_available.svg';
import { ReactComponent as EventUpcoming } from './svg-icons/event_upcoming.svg';
import { ReactComponent as PatientPositioning } from './svg-icons/patient_positioning.svg';

export const stringToJSXMap = {
  [Icons.Cancel]: <Cancel />,
  [Icons.ChangeDescription]: <ChangeDescription />,
  [Icons.ChangeLocation]: <ChangeLocation />,
  [Icons.Inserted]: <Inserted />,
  [Icons.Reordered]: <Reordered />,
  [Icons.EventBusy]: <EventBusy />,
  [Icons.Lock]: <Lock />,
  [Icons.Report]: <Report />,
  [Icons.EventAvailable]: <EventAvailable />,
  [Icons.EventUpcoming]: <EventUpcoming />,
  [Icons.PatientPositioning]: <PatientPositioning />,
} as const;

type StringToJSXMap = typeof stringToJSXMap;
export type StringToJSXMapKeys = keyof StringToJSXMap;

export interface SIconProps {
  icon?: StringToJSXMapKeys;
  size?: number;
  color?: string;
  alternative?: ReactElement;
}

export function SIcon({ icon, size, color = 'white', alternative }: SIconProps) {
  if (!icon || !stringToJSXMap[icon])
    return alternative ? alternative : <Icon icon="octicon:question-24" fontSize={30} />;

  return cloneElement(stringToJSXMap[icon], {
    width: size,
    height: size,
    color: color,
    fill: color,
  });
}

export default SIcon;
