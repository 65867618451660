import BedView from '../ward-overview-dashboard/components/bed-view/bed-view';
import { BedAllocationWardListItemBed } from '@sqior/viewmodels/occupancy';
import { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { OperationContext } from '@sqior/react/operation';
import styles from './bed-view-multi-alloc.module.css';

export interface BedViewMultiAllocProps {
  bed: BedAllocationWardListItemBed;
}

export function BedViewMultiAlloc({ bed }: BedViewMultiAllocProps) {
  const dispatcher = useContext(OperationContext);
  const [selectedId, setSelectedId] = useState<string>('');

  const patients = [bed.current, ...bed.other]; // All patients in a list to find next patient to select
  const selectedIndex = selectedId ? patients.findIndex((p) => p.patient?.id === selectedId) : 0;
  const nextIndex = (selectedIndex + 1) % patients.length;
  const onBedClick = () => {
    const selectPatient = patients[selectedId ? nextIndex : selectedIndex];
    if (selectPatient.select) dispatcher.start(selectPatient.select);
    setSelectedId(selectPatient.patient?.id || '');
  };

  const getPatientTurn = (idx: number) => {
    const otherIndex = idx - 1;
    if (otherIndex < 0) return undefined;
    return otherIndex;
  };

  return (
    <div className={styles['container']}>
      <BedView
        key={bed.id}
        bed={bed}
        shadow={bed.other.length > 0}
        onClick={onBedClick}
        other={getPatientTurn(nextIndex)}
      />

      {bed.other && bed.other[0] && (
        <motion.div
          style={{
            position: 'absolute',
            top: 5,
            left: 10,
            width: '100%',
          }}
        >
          <BedView bed={bed} onClick={onBedClick} other={getPatientTurn(selectedIndex)} />
        </motion.div>
      )}
    </div>
  );
}

export default BedViewMultiAlloc;
