import { isEqual, ValueObject } from '@sqior/js/data';
import { AddressGroupInfoVM } from '@sqior/viewmodels/communication';
import { TeamAddresses, TeamHeader } from '@sqior/react/uichat';
import { useEffect, useState } from 'react';
import styles from './workflow-base-area.module.css';
import { CaveInfo, CaveInfoProps, CaveInfosFoldable } from '@sqior/react/uibase';
import { CaveSeverity } from '@sqior/viewmodels/patient';

/* eslint-disable-next-line */
export interface WorkflowBaseAreaProps {
  headers: React.ReactNode | React.ReactNode[];
  headersEnd?: React.ReactNode | React.ReactNode[];
  children: React.ReactNode | React.ReactNode[];
  relatedId?: ValueObject;
  team?: AddressGroupInfoVM[];
  caveInfos?: CaveInfoProps[];
}

function severityToNumber(severity?: CaveSeverity): number {
  if (severity === undefined) {
    return -1;
  }
  return {
    info: 0,
    warning: 1,
    critical: 2,
  }[severity];
}

function worstCaveInfo(infos: CaveInfoProps[]): CaveInfoProps {
  return infos.reduce((max, current) => {
    return severityToNumber(current.severity) > severityToNumber(max.severity) ? current : max;
  });
}

export function WorkflowBaseArea(props: WorkflowBaseAreaProps) {
  const [displayTeam, setDisplayTeam] = useState(false);
  const [oldRelatedId, setOldRelatedId] = useState<ValueObject | undefined>(undefined);
  function switchDisplayTeam() {
    setDisplayTeam(() => !displayTeam);
  }

  // Do not display team in case the relatedId changes (which means the underlaying dato changed)
  useEffect(() => {
    if (!isEqual(oldRelatedId, props.relatedId)) {
      setDisplayTeam(false);
      setOldRelatedId(props.relatedId);
    }
  }, [oldRelatedId, props.relatedId]);

  const worstCave: CaveInfoProps =
    props.caveInfos && props.caveInfos?.length > 0
      ? worstCaveInfo(props.caveInfos)
      : {
          severity: CaveSeverity.Info,
          description: '',
        };

  const sortedCaves = props.caveInfos
    ? [...props.caveInfos].sort((item1: CaveInfoProps, item2: CaveInfoProps) => {
        return severityToNumber(item2.severity) - severityToNumber(item1.severity);
      })
    : undefined;
  const severities = sortedCaves
    ? sortedCaves.map((item) => {
        return item.severity;
      })
    : [];

  let truncatedDescription = '';
  if (worstCave.description) {
    truncatedDescription = worstCave.description.substring(0, 25);
    if (truncatedDescription.length < worstCave.description.length) {
      truncatedDescription = truncatedDescription.substring(
        0,
        truncatedDescription.lastIndexOf(' ', 25)
      );
    }
  }

  return (
    <div className={styles['container']}>
      <div className={styles['procedure-header']}>
        {props.headers}
        {props.team && (
          <TeamHeader
            team={props.team}
            displayTeam={displayTeam}
            switchDisplayTeam={switchDisplayTeam}
            relatedId={props.relatedId}
          />
        )}
        {props.headersEnd && props.headersEnd}
      </div>
      <div className={styles['content']}>
        <div className={styles['content-inner']}>
          <CaveInfosFoldable severities={severities} defaultText={truncatedDescription}>
            {sortedCaves &&
              sortedCaves.map((item: CaveInfoProps, index) => (
                <CaveInfo
                  key={index}
                  severity={item.severity}
                  description={item.description}
                ></CaveInfo>
              ))}
          </CaveInfosFoldable>
          {props.children}
        </div>
        <TeamAddresses
          team={props.team}
          displayTeam={displayTeam}
          switchDisplayTeam={switchDisplayTeam}
          relatedId={props.relatedId}
          className={styles['content-addresses']}
        />
      </div>
    </div>
  );
}

export default WorkflowBaseArea;
