import styles from './sqior-marker.module.css';
import {
  ORWorkflowOverviewDashboardMarker,
  ORWorkflowOverviewDashboardMarkerGroup,
  ORWorkflowOverviewDashboardMarkerType,
} from '@sqior/viewmodels/orworkflow';
import React, { FC, useRef } from 'react';
import { motion } from 'framer-motion';
import { useCustomTimer } from '@sqior/react/state';
import { addSeconds } from '@sqior/js/data';

interface ConvertTimestampProps {
  timestamp: number;
  start: number;
  end: number;
}

const convertTimestamp = ({ timestamp, start, end }: ConvertTimestampProps) => {
  return ((timestamp - start) / (end - start)) * 100;
};

const colors = {
  blue: {
    inactiveBackground: 'rgb(7,81,117)',
    activeBackgrund: '#1cade4',
    text: '#090f23',
  },
  rosa: {
    inactiveBackground: '#5e315d',
    activeBackgrund: '#ED7BE8',
    text: 'rgba(255, 156, 255)',
  },
};

interface SqiorMarkerProps {
  data: ORWorkflowOverviewDashboardMarker;
  start: number;
  end: number;
  startMarginRight?: number;
  endMarginRight?: number;
  stopActiveColor?: number;
}
const WIDTH = 8;

export const SqiorMarker: FC<SqiorMarkerProps> = ({
  data,
  start,
  end,
  startMarginRight,
  endMarginRight,
  stopActiveColor,
}) => {
  const markerRef = useRef(null);
  const currentTime = useCustomTimer(addSeconds(10));
  const top = convertTimestamp({ timestamp: data.timestamp, start, end });
  const variant = data.type === ORWorkflowOverviewDashboardMarkerType.End ? 'end' : 'start';
  const translateY = variant === 'start' ? -100 : 0;

  const getMarginRight = (): number => {
    if (variant === 'start') {
      return startMarginRight || 0;
    }

    return endMarginRight || 0;
  };

  return (
    <motion.div
      ref={markerRef}
      className={styles['container']}
      style={{
        top: `${top}%`,
        borderStyle: 'solid',
        translateX: getMarginRight(),
        ...applyMarkerMode(variant, data, currentTime, stopActiveColor),
      }}
      transformTemplate={(_, generated) => `translateY(${translateY}%) ${generated}`}
    />
  );
};

export default SqiorMarker;

const applyMarkerMode = (
  variant: 'start' | 'end',
  data: ORWorkflowOverviewDashboardMarker,
  currentTime: number,
  stopActiveColor?: number
): React.CSSProperties => {
  const color = data.group === ORWorkflowOverviewDashboardMarkerGroup.Anesthesia ? 'rosa' : 'blue';
  const inactiveBackground = colors[color].inactiveBackground;
  const activeBackground = colors[color].activeBackgrund;

  const getBackgroundColorWithLimit = (): string => {
    if (!stopActiveColor) {
      return inactiveBackground;
    }

    if (currentTime > stopActiveColor) {
      return inactiveBackground;
    }

    if (currentTime < data.timestamp) {
      return inactiveBackground;
    }

    return activeBackground;
  };

  const backgroundWithLimit = getBackgroundColorWithLimit();

  switch (variant) {
    case 'start':
      return {
        borderWidth: `0 0 ${WIDTH}px ${WIDTH}px`,
        borderColor: `transparent transparent ${backgroundWithLimit} transparent`,
      };
    case 'end':
      return {
        borderWidth: `0 ${WIDTH + 3.5}px ${WIDTH + 3.5}px 0`,
        borderColor: `transparent ${backgroundWithLimit} transparent transparent`,
      };
    default:
      return {
        borderColor: `transparent transparent transparent transparent`,
      };
  }
};
