import { classes } from '@sqior/react/utils';
import { ReactComponent as IconBadge } from './icon-badge.svg';
import { ReactComponent as IconCall1 } from './icon-call1.svg';
import { ReactComponent as IconCall2 } from './icon-call2.svg';
import { ReactComponent as IconChat } from './icon-chat.svg';
import { ReactComponent as IconChatEmpty } from './icon-chat-empty.svg';
import { ReactComponent as IconChatRound } from './icon-chat-round.svg';
import { ReactComponent as IconDashboard } from './icon-dashboard.svg';
import { ReactComponent as IconEmail } from './icon-email.svg';
import { ReactComponent as IconExpandLess } from './icon-expand-less.svg';
import { ReactComponent as IconExpandMore } from './icon-expand-more.svg';
import { ReactComponent as IconNext } from './next.svg';
import { ReactComponent as IconHistory } from './icon-history.svg';
import { ReactComponent as IconNavigateBefore } from './icon-navigate-before.svg';
import { ReactComponent as IconNavigateNext } from './icon-navigate-next.svg';
import { ReactComponent as IconPatient } from './icon-patient.svg';
import { ReactComponent as IconPerson } from './icon-person.svg';
import { ReactComponent as IconPersonGroup } from './icon-group.svg';
import { ReactComponent as IconTasks } from './icon-tasks.svg';
import { ReactComponent as IconTeam } from './icon-team.svg';
import { ReactComponent as IconTeam2 } from './icon-team2.svg';
import { ReactComponent as IconCalendarMonth } from './calendar_month.svg';
import { ReactComponent as IconEventUpcoming } from './event_upcoming.svg';
import { ReactComponent as IconQRCode } from './qr_code.svg';
import { ReactComponent as IconUploadFile } from './upload_file.svg';
import { ReactComponent as IconShortStay } from './short-stay.svg';
import { ReactComponent as IconWarning } from './problem.svg';
import { ReactComponent as IconAccountBalance } from './account_balance.svg';
import { ReactComponent as IconSignature } from './icon-signature.svg';
import { ReactComponent as IconCheckBox } from './check-box.svg';
import { ReactComponent as IconTodo } from './todo.svg';
import { ReactComponent as IconRoles } from './roles.svg';
import { ReactComponent as IconComment } from './comment.svg';
import { ReactComponent as IconAnzeige } from './anzeige.svg';
import { ReactComponent as IconDevices } from './devices.svg';
import { ReactComponent as IconAnalytics } from './analytics.svg';
import { ReactComponent as IconCalendar } from './calendar.svg';
import { ReactComponent as IconChart } from './chart.svg';
import { ReactComponent as IconClock } from './clock.svg';
import { ReactComponent as IconAccountTree } from './account_tree.svg';
import { ReactComponent as IconContactSupport } from './contact_support.svg';
import { ReactComponent as IconLock } from './lock.svg';
import { ReactComponent as IconInfo } from './info.svg';
import { ReactComponent as IconExitToApp } from './exit_to_app.svg';
import { ReactComponent as IconIphone } from './iphone.svg';
import { ReactComponent as IconQuiz } from './quiz.svg';
import { ReactComponent as IconSupportChat } from './support-chat.svg';
import { ReactComponent as IconCheck } from './icon-check.svg';

import styles from './svg-icon.module.css';
import { CSSProperties } from 'react';

export type SvgIconProps = {
  className?: string;
  style?: CSSProperties;
};

export function SvgIconBadge(props: SvgIconProps) {
  return (
    <IconBadge
      viewBox="0 0 40 40"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconCall1(props: SvgIconProps) {
  return (
    <IconCall1
      viewBox="0 0 40 40"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconCall2(props: SvgIconProps) {
  return (
    <IconCall2
      viewBox="0 0 40 40"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconChat(props: SvgIconProps) {
  return (
    <IconChat
      viewBox="0 0 40 40"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconChatEmpty(props: SvgIconProps) {
  return (
    <IconChatEmpty
      viewBox="0 0 40 40"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconChatRound(props: SvgIconProps) {
  return (
    <IconChatRound
      viewBox="0 0 48 48"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconDashboard(props: SvgIconProps) {
  return (
    <IconDashboard
      viewBox="0 0 40 40"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconEmail(props: SvgIconProps) {
  return (
    <IconEmail
      viewBox="0 0 40 40"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconExpandLess(props: SvgIconProps) {
  return (
    <IconExpandLess
      viewBox="0 0 40 40"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconExpandMore(props: SvgIconProps) {
  return (
    <IconExpandMore
      viewBox="0 0 40 40"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconNext(props: SvgIconProps) {
  return <IconNext className={classes(styles['std-icon'], props.className)} {...props} />;
}
export function SvgIconGroup(props: SvgIconProps) {
  return (
    <IconTeam2
      viewBox="0 0 40 40"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconHistory(props: SvgIconProps) {
  return (
    <IconHistory
      viewBox="0 96 960 960"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconNavigateBefore(props: SvgIconProps) {
  return (
    <IconNavigateBefore
      viewBox="0 96 960 960"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconNavigateNext(props: SvgIconProps) {
  return (
    <IconNavigateNext
      viewBox="0 96 960 960"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconPatient(props: SvgIconProps) {
  return (
    <IconPatient
      viewBox="0 0 48 48"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconPerson(props: SvgIconProps) {
  return <IconPerson className={classes(styles['std-icon'], props.className)} {...props} />;
}
export function SvgIconPersonGroup(props: SvgIconProps) {
  return <IconPersonGroup className={classes(styles['std-icon'], props.className)} {...props} />;
}
export function SvgIconTasks(props: SvgIconProps) {
  return (
    <IconTasks
      viewBox="0 0 40 40"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}
export function SvgIconTeam(props: SvgIconProps) {
  return (
    <IconTeam
      viewBox="0 0 40 40"
      className={classes(styles['std-icon'], props.className)}
      {...props}
    />
  );
}

export function SvgCalendarMonth(props: SvgIconProps) {
  return <IconCalendarMonth className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SvgEventUpcoming(props: SvgIconProps) {
  return <IconEventUpcoming className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SvgQRCode(props: SvgIconProps) {
  return <IconQRCode className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SvgUploadFile(props: SvgIconProps) {
  return <IconUploadFile className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SvgShortStay(props: SvgIconProps) {
  return <IconShortStay className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SvgWarning(props: SvgIconProps) {
  return <IconWarning className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SvgAccountBalance(props: SvgIconProps) {
  return <IconAccountBalance className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SvgIconSignature(props: SvgIconProps) {
  return <IconSignature className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SvgCheckBox(props: SvgIconProps) {
  return <IconCheckBox className={classes(styles['std-icon'], props.className)} {...props} />;
}
export function SvgTodo(props: SvgIconProps) {
  return <IconTodo className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SVGRoles(props: SvgIconProps) {
  return <IconRoles className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SvgComment(props: SvgIconProps) {
  return <IconComment className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SvgAnzeige(props: SvgIconProps) {
  return <IconAnzeige className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SvgDevices(props: SvgIconProps) {
  return <IconDevices className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SvgAnalytics(props: SvgIconProps) {
  return <IconAnalytics className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SvgCalendar(props: SvgIconProps) {
  return <IconCalendar {...props} />;
}

export function SvgChart(props: SvgIconProps) {
  return <IconChart className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SvgClock(props: SvgIconProps) {
  return <IconClock className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SvgAccountTree(props: SvgIconProps) {
  return <IconAccountTree className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SvgContactSupport(props: SvgIconProps) {
  return <IconContactSupport className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SvgLock(props: SvgIconProps) {
  return <IconLock className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SvgInfo(props: SvgIconProps) {
  return <IconInfo className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SvgExitToApp(props: SvgIconProps) {
  return <IconExitToApp className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SvgIphone(props: SvgIconProps) {
  return <IconIphone className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SvgIconQuiz(props: SvgIconProps) {
  return <IconQuiz className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SvgSupportChat(props: SvgIconProps) {
  return <IconSupportChat className={classes(styles['std-icon'], props.className)} {...props} />;
}

export function SvgCheckIcon(props: SvgIconProps) {
  return <IconCheck className={classes(styles['std-icon'], props.className)} {...props} />;
}
