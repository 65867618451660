import { AsyncIterator } from './async-iterator';

/** Transform an array with an asynchronous function ensuring that all transformations are performed concurrently */
export async function arrayTransformAsync<SourceType, TargetType>(
  input: SourceType[] | undefined,
  func: (
    input: SourceType,
    index: number
  ) => TargetType | undefined | Promise<TargetType | undefined>
): Promise<TargetType[]> {
  if (!input) return [];
  const proms = input.map((inVal, index) => {
    return func(inVal, index);
  });
  const res: TargetType[] = [];
  for (const prom of proms) {
    const value = await prom;
    if (value !== undefined) res.push(value);
  }
  return res;
}

/** Transform an array with an asynchronous function resulting in arrays of the same type */
export async function arrayConcatAsync<SourceType, TargetType>(
  input: SourceType[] | undefined,
  func: (input: SourceType, index: number) => Promise<TargetType[]>
): Promise<TargetType[]> {
  if (!input) return [];
  const proms: Promise<TargetType[]>[] = [];
  for (let i = 0; i < input.length; i++) proms.push(func(input[i], i));
  let res: TargetType[] = [];
  for (const prom of proms) res = res.concat(await prom);
  return res;
}

/** Only return the items which predicate is true */
export async function arrayFilterAsync<Type>(
  input: Type[] | undefined,
  predicate: (v: Type) => Promise<boolean>
) {
  if (!input) return [];
  return Promise.all(input.map(predicate)).then((results) =>
    input.filter((_v, index) => results[index])
  );
}

/** Helper function to transform an AsyncIterator to an array */
export async function asyncIteratorToArray<Type>(asyncIterator: AsyncIterator<Type>) {
  const arr: Type[] = [];
  for await (const i of asyncIterator) if (i !== undefined) arr.push(i);
  return arr;
}
