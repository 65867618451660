import { ClockTimestamp } from '@sqior/js/data';
import { Message } from '@sqior/js/message';

export enum ConnectionMessageType {
  HandshakeInit = 'internal.handhake.init',
  HandshakeResponse = 'internal.handhake.response',

  AuthRequest = 'internal.auth.request',
  AuthError = 'internal.auth.error',

  PingRequest = 'internal.ping.request',
  PingResponse = 'internal.ping.response',

  LogOut = 'internal.logOut',
}

export type InternalMessage = Message;

export type HandshakeInitMessage = InternalMessage & {
  version?: string; // Version of the client, always contained - made optional for backward compatibility
  sessionIdentifier?: string; // If specified, an existing session will be restored; otherwise a new session will be created
  authToken?: string; // Authorization token to verify access rights
  subUserId?: string; // Additional identifier further specializing a pot. generic user account
  sessionStart?: ClockTimestamp; // Session start timestamp
};

export enum HandshakeResponseCode {
  OK = 'OK',
  AuthenticationError = 'AUTH_ERROR',
  CompatibilityError = 'COMPAT_ERROR',
  UnknownError = 'ERROR',
}

export type HandshakeResponseMessage = InternalMessage & {
  response: string; // Handshake response code
  error?: string; // Detailed error code
  sessionIdentifier?: string; // Session identifier generated by the server, might be the same as the sessionIdentifier specified in HandshakeInitMessage
};

export type AuthRequestMessage = Message & {
  authToken: string;
};

/** Authentication error after successful initial handshake */

export enum AuthErrorCode {
  TokenExpired = 'token-expired',
  SessionExpired = 'session-expired',
  InvalidCredentials = 'invalid-credentials',
}

export type AuthErrorMessage = Message & {
  error?: string;
};

export type PingMessage = InternalMessage & {
  identifier: number;
  timestamp: number;
};

export function isConnectionMessage(msg: Message): boolean {
  return Object.values(ConnectionMessageType).some((e: string) => e === msg.type);
}
